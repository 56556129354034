<template>
  <div>
    <BreadcrumbComponent :items=items></BreadcrumbComponent>
    <v-container>
      <v-row>
        <!-- Cartes statistiques -->
        <v-col cols="12" md="4" sm="6">
          <a href='/sales'>
            <v-card >
              <v-flex class="d-flex">
                <v-card-title class="w-100">Rapport de Ventes</v-card-title>
                <v-icon class="p-4">mdi-cart-outline</v-icon>
              </v-flex>
            </v-card>
          </a>
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <a href='/productsreport'>
            <v-card >
              <v-flex class="d-flex">
                <v-card-title class="w-100">Rapport Produit</v-card-title>
                <v-icon class="p-4">mdi-format-list-bulleted-type</v-icon>
              </v-flex>
            </v-card>
          </a>
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <a href='/agents'>
            <v-card >
              <v-flex class="d-flex">
                <v-card-title class="w-100">Agents</v-card-title>
                <v-icon class="p-4">mdi-account-multiple-outline</v-icon>
              </v-flex>
            </v-card>
          </a>
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <a href='/closing'>
            <v-card >
              <v-flex class="d-flex">
                <v-card-title class="w-100">Rapport de Caisse</v-card-title>
                <v-icon class="p-4">mdi-cash-multiple</v-icon>
              </v-flex>
            </v-card>
          </a>
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <a href='/catalog'>
            <v-card >
              <v-flex class="d-flex">
                <v-card-title class="w-100">Catalogue</v-card-title>
                <v-icon class="p-4">mdi-cash-100</v-icon>
              </v-flex>
            </v-card>
          </a>
        </v-col>

        <v-col cols="12" md="4" sm="6">
          <a href='/rates'>
            <v-card >
              <v-flex class="d-flex">
                <v-card-title class="w-100">Taux du Jour</v-card-title>
                <v-icon class="p-4">mdi-format-list-bulleted</v-icon>
              </v-flex>
            </v-card>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'

export default {
  name: 'HomeView',
  methods: {
    getStats: function () {
      var self = this;
      this.$axios.get('/categories')
        .then(function (response) {
          self.categories = response.data
        })
    },
  },
  data: () => ({
    items: [
      {
        text: 'ACCUEIL',
        disabled: false,
        href: '/',
      }
    ],
    fluid: true,
    business: [],
    profile: [],
  }),
  components: { BreadcrumbComponent },
}
</script>

<style type="text/css">
.v-data-table__wrapper {
  border: 2px solid #f3f3f3;
}
</style>